















import {Vue, Component, Prop} from 'vue-property-decorator';
import {EmployeePermission} from "@/dto/payroll/EmployeeDto";
import {prettyEnum} from "../../../../../utils/StringUtils";

@Component({
	methods: {prettyEnum}

})
export default class EmployeePermissionHolder extends Vue {

	@Prop()
	private permission!: EmployeePermission

}
