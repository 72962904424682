


















import {Component, Prop} from 'vue-property-decorator';
import EmployeeDto, {
	CreateEmployeePermissionPayload,
	EmployeePermission,
	EmployeePermissionType
} from "@/dto/payroll/EmployeeDto";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import Application from "@/state/Application";
import EmployeeService from "@/services/EmployeeService";
import EmployeePermissionHolder from "@/components/payroll/business/employments/employee/EmployeePermissionHolder.vue";
import CompanyService from "@/services/CompanyService";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import SelectOption from "@/components/common/SelectOption";
import {prettyEnum} from "@/utils/StringUtils";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {processError} from "@/utils/ComponentUtils";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

@Component({
	components: {PortalSelect, EmployeePermissionHolder}
})
export default class EmployeePermissions extends EmploymentVue<EmployeeDto> {

	@Prop()
	employment!: EmployeeDto

	@Prop()
	reload!: () => void

	private permissions: Array<EmployeePermission> = []
	private projects: Array<ProjectDTO> = []

	private req = new CreateEmployeePermissionPayload(
		{
			employmentId: this.employment.id
		}
	)

	created() {
		this.getProjects();
		this.getPermissions()
	}

	private getPermissions() {
		Application.startLoading()
		EmployeeService.getPermissions(this.employment.id!).then(
			permissions => {
				this.permissions = permissions.data;
				Application.stopLoading();
			},
			err => processError(err, this)
		)
	}

	private getProjects() {
		Application.startLoading()
		CompanyService.getProjectsByCompanyId(this.employment.employer.id).then(
			projects => {
				this.projects = projects.data;
				Application.stopLoading();
			},
			err => processError(err, this)
		)
	}

	get permissionOptions(): Array<SelectOption> {
		return Object.values(EmployeePermissionType).map(it => SelectOption.builder().title(prettyEnum(it)).value(it).build())
	}

	get projectOptions(): Array<SelectOption> {
		return this.projects.filter(it => this.employment.projects.map(pr => pr.id).indexOf(it.id) > 0).map(it => SelectOption.builder().title(it.title).value(it.id).build())
	}

	private createPermission() {
		Application.startLoading()
		EmployeeService.createPermission(this.req).then(
			() => {
				this.getPermissions()
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private showDeleteModal(permission: EmployeePermission) {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: prettyEnum(permission.permissionType),
				onDelete: () => {
					Application.startLoading()
					EmployeeService.deletePermission(permission.id).then(
						() => {
							this.getProjects()
							this.getPermissions()
							this.reload()
							Application.stopLoading()
						},
						err => processError(err, this)
					)
				}
			}
		)
	}
}
